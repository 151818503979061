<template>
  <MasterLayout :main_name="main_name" :link="link">
    <div class="sidebar_right_content booking_content">
      <div id="Mechanics" class="w3-container w3-border city">
        <ul class="ui-formSlide">
          <!--  book villa three section -->
          <li data-step="bk_3">
            <div class="bk_title">Your can Book from here also</div>
            <div class="bk_title_main">
              Accommodation Fit For
              <span class="font_bold"
                >{{ userProfile ? userProfile.first_name : "" }}'s</span
              >
              Profile
            </div>
            <div class="datePicker">
              <Datepicker
                type="date"
                :min="new Date().toISOString().substr(0, 10)"
                @closed="dateChange()"
                v-model="form.rangeDate"
                placeholder="Check In Check Out"
                format="dd-MM-yyyy"
                value-format="dd-MM-yyyy"
                :min-date="new Date()"
                modelAuto
                range
                multiCalendars
                multiCalendarsSolo
              />
              <div
                class="invalid-feedback fw-bold fst-italic text-danger mt-1"
                :style="{ display: checkInDateError }"
              >
                Please enter checkin checkout Date.
              </div>
            </div>
            <div class="bk_title_info_info_main_row" v-if="form.rangeDate">
              <div v-if="userProfile.user_type == 'Guest'" class="w-100">
                <div v-if="villaList.length" class="villa_card_wrapper">
                  <div
                    class="bk_title_info_info"
                    v-for="(villa, index) in villaList"
                    :key="villa.villaTypeId"
                  >
                    <div
                      class="bk_title_info_info_inr"
                      @click="villaClick(villa.villaTypeId)"
                    >
                      <div
                        class="bk_title_info cursor_pointer"
                        :class="
                          clickVillaId.find((c) => c == villa.villaTypeId)
                            ? `activeClick`
                            : ``
                        "
                      >
                        <div class="bk_title_img">
                          <a
                            :href="villa.villa_images[0]"
                            data-lightbox="models"
                            data-title="STRUCTURE-2"
                          >
                            <img :src="villa.villa_images[0]" />
                          </a>
                          <!-- <span>View Vila</span> -->
                        </div>
                        <div class="bk_content">
                          <div class="vila_title">
                            {{ villa.villa_name.toLowerCase() }}
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                          >
                            <span> Max Quantity </span>
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.37281 2.22601C5.86566 1.80965 5.13499 1.80965 4.62784 2.22601L2.16867 4.24496C1.95659 4.41907 1.83366 4.67904 1.83366 4.95344V8.25003C1.83366 8.75629 2.24406 9.1667 2.75033 9.1667H8.25033C8.75659 9.1667 9.16699 8.75629 9.16699 8.25003V4.95344C9.16699 4.67904 9.04407 4.41907 8.83198 4.24496L6.37281 2.22601ZM4.04618 1.51752C4.89143 0.823586 6.10922 0.823587 6.95447 1.51752L9.41364 3.53647C9.8378 3.88471 10.0837 4.40464 10.0837 4.95344V8.25003C10.0837 9.26255 9.26285 10.0834 8.25033 10.0834H2.75033C1.7378 10.0834 0.916992 9.26255 0.916992 8.25003V4.95344C0.916992 4.40464 1.16285 3.88471 1.58701 3.53647L4.04618 1.51752Z"
                                fill="#153936"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M3.66699 7.79171C3.66699 6.77919 4.4878 5.95837 5.50033 5.95837C6.51285 5.95837 7.33366 6.77919 7.33366 7.79171V9.62504H6.41699V7.79171C6.41699 7.28545 6.00659 6.87504 5.50033 6.87504C4.99406 6.87504 4.58366 7.28545 4.58366 7.79171V9.62504H3.66699V7.79171Z"
                                fill="#153936"
                              />
                            </svg>
                            <span>1 &nbsp;&nbsp; x &nbsp; </span>
                            <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.83301 8.70829C1.83301 7.44264 2.85902 6.41663 4.12467 6.41663H6.87467C8.14033 6.41663 9.16634 7.44264 9.16634 8.70829C9.16634 9.46768 8.55073 10.0833 7.79134 10.0833H3.20801C2.44862 10.0833 1.83301 9.46768 1.83301 8.70829ZM4.12467 7.33329C3.36528 7.33329 2.74967 7.9489 2.74967 8.70829C2.74967 8.96142 2.95488 9.16663 3.20801 9.16663H7.79134C8.04447 9.16663 8.24967 8.96142 8.24967 8.70829C8.24967 7.9489 7.63407 7.33329 6.87467 7.33329H4.12467Z"
                                fill="#153936"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M5.49967 1.83329C4.74028 1.83329 4.12467 2.4489 4.12467 3.20829C4.12467 3.96768 4.74028 4.58329 5.49967 4.58329C6.25907 4.58329 6.87467 3.96768 6.87467 3.20829C6.87467 2.4489 6.25907 1.83329 5.49967 1.83329ZM3.20801 3.20829C3.20801 1.94264 4.23402 0.916626 5.49967 0.916626C6.76533 0.916626 7.79134 1.94264 7.79134 3.20829C7.79134 4.47395 6.76533 5.49996 5.49967 5.49996C4.23402 5.49996 3.20801 4.47395 3.20801 3.20829Z"
                                fill="#153936"
                              />
                            </svg>
                            <span class="font_bold">{{
                              villa.allow_adult
                            }}</span>
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                          >
                            <span> Price </span>&nbsp;:&nbsp;
                            <span class="font_bold"
                              ><span class="rupee">₹</span
                              >{{
                                villa.price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}.00</span
                            >
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                            v-if="villa.price != villa.weekend_price"
                          >
                            <span> Weekend Price </span>&nbsp;:&nbsp;
                            <span class="font_bold"
                              ><span class="rupee">₹</span
                              >{{
                                villa.weekend_price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}.00</span
                            >
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                            v-if="villa.max_adult_limit"
                          >
                            <span> Max Adult Limit</span>&nbsp;:&nbsp;
                            <span class="font_bold">{{
                              villa.max_adult_limit
                            }}</span>
                          </div>
                          <div
                            class="vila_title_info"
                            :class="
                              clickVillaId.find((c) => c == villa.villaTypeId)
                                ? `activeClick`
                                : ``
                            "
                            v-if="villa.extra_per_person_charge"
                          >
                            <span> Extra Person</span>&nbsp;:&nbsp;
                            <span class="font_bold"
                              ><span class="rupee">₹</span
                              >{{
                                parseFloat(villa.extra_per_person_charge || 0)
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                              }}.00</span
                            >
                          </div>
                        </div>
                      </div>
                      <div class="vialla_qty_main_inner" v-if="!villa.message">
                        <div class="vialla_qty_main">
                          <!-- <span>Villa Qty</span> -->
                          <div class="main_class_inner_sd select_image">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.11076 2.83302C7.4653 2.30311 6.53535 2.30311 5.88989 2.83302L2.76003 5.4026C2.49011 5.6242 2.33366 5.95507 2.33366 6.3043V10.5C2.33366 11.1443 2.85599 11.6666 3.50033 11.6666H10.5003C11.1447 11.6666 11.667 11.1443 11.667 10.5V6.3043C11.667 5.95507 11.5105 5.6242 11.2406 5.4026L8.11076 2.83302ZM5.1496 1.93131C6.22536 1.04812 7.77529 1.04812 8.85106 1.93132L11.9809 4.50089C12.5208 4.94409 12.8337 5.60583 12.8337 6.3043V10.5C12.8337 11.7886 11.789 12.8333 10.5003 12.8333H3.50033C2.21166 12.8333 1.16699 11.7886 1.16699 10.5V6.3043C1.16699 5.60583 1.4799 4.94409 2.01974 4.50089L5.1496 1.93131Z"
                                fill="#153936"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M4.66699 9.91671C4.66699 8.62804 5.71166 7.58337 7.00033 7.58337C8.28899 7.58337 9.33366 8.62804 9.33366 9.91671V12.25H8.16699V9.91671C8.16699 9.27238 7.64466 8.75004 7.00033 8.75004C6.35599 8.75004 5.83366 9.27238 5.83366 9.91671V12.25H4.66699V9.91671Z"
                                fill="#153936"
                              />
                            </svg>
                            <select
                              :id="`qty_${villa.villaTypeId}`"
                              name="villaQty"
                              v-model="villa_type[villa.pack_type].qty"
                              @change="
                                onChangeVillaQty(
                                  villa.villaTypeId,
                                  villa_type[villa.pack_type].qty,
                                  villa_type[villa.pack_type].person
                                )
                              "
                            >
                              <option :value="0" disabled hidden>Qty</option>
                              <option
                                v-for="(vQty, vIndex) in villa.villa_quantity"
                                :key="villa.villaTypeId + '_' + vIndex"
                                :value="villa.villaTypeId + '_' + vQty"
                              >
                                {{ vQty }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <div class="vialla_qty_main">
                          <!-- <span>Total Prax</span> -->
                          <div class="main_class_inner_sd select_image">
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                                fill="#153936"
                              />
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                                fill="#153936"
                              />
                            </svg>
                            <select
                              :id="`person_${villa.villaTypeId}`"
                              name="villaPerson"
                              v-model="villa_type[villa.pack_type].person"
                              :disabled="villa_type[villa.pack_type].qty <= 0"
                              @change="
                                onChangeVillaQty(
                                  villa.villaTypeId,
                                  villa_type[villa.pack_type].qty,
                                  villa_type[villa.pack_type].person
                                )
                              "
                            >
                              <option :value="0" disabled hidden>Person</option>
                              <option
                                v-for="(pQty, pIndex) in villa_booking[index]
                                  .quantity * villa.max_adult_limit"
                                :key="villa.villaTypeId + '_' + pIndex"
                                :value="villa.villaTypeId + '_' + pQty"
                              >
                                {{ pQty }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div class="vialla_qty_main_inner text-danger" v-else>
                        {{ villa.message }}
                      </div>
                      <p
                        class="reverse_error"
                        v-if="reserve_error[villa.pack_type].error"
                      >
                        {{ reserve_error[villa.pack_type].error }}
                      </p>
                    </div>
                  </div>
                </div>
                <div v-else class="bk_title noslotavailable">
                  No Slots Available!
                </div>
              </div>
              <div v-if="userProfile.user_type == 'Owner'" class="w-100">
                <div
                  class="villa_card_owner_wrapper"
                  v-if="is_owner_villa_avilable"
                >
                  <button
                    class="main_button_user"
                    type="submit"
                    @click="ownerSelfBooking()"
                  >
                    <span>Book For My Self</span>
                  </button>
                  <button
                    class="main_button_user"
                    type="submit"
                    @click="ownerOtherBookingShow()"
                  >
                    <span>Booking For Other</span>
                  </button>
                </div>
                <div
                  v-else-if="
                    !is_owner_villa_avilable &&
                    is_owner_other_booking_show == false
                  "
                  class="bk_title noslotavailable"
                >
                  Villa Not Available!
                </div>
              </div>
              <div
                class="w-100 villa_card_owner_other_wrapper"
                v-if="
                  userProfile.user_type == 'Owner' &&
                  is_owner_other_booking_show
                "
              >
                <div class="row">
                  <div class="col-6">
                    <label>First Name<span style="color: red">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="first_name"
                      v-model="persons[0].first_name"
                    />
                    <div class="error" v-if="owner_errors?.first_name">
                      {{ owner_errors?.first_name[0] }}
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Middle Name<span style="color: red"></span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="middle_name"
                      v-model="persons[0].middle_name"
                    />
                    <div class="error" v-if="owner_errors?.middle_name">
                      {{ owner_errors?.middle_name[0] }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>Last Name<span style="color: red">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="last_name"
                      v-model="persons[0].last_name"
                    />
                    <div class="error" v-if="owner_errors?.last_name">
                      {{ owner_errors?.last_name[0] }}
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Phone Number<span style="color: red">*</span></label>
                    <input
                      type="text"
                      class="form-control"
                      name="phone_number"
                      v-model="persons[0].mobile"
                    />
                    <div class="error" v-if="owner_errors?.phone_number">
                      {{ owner_errors?.phone_number[0] }}
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6">
                    <label>Adults<span style="color: red">*</span></label>
                    <select
                      @change="selectAdults($event)"
                      class="form-control"
                      v-model="villa_booking[0].person"
                    >
                      <option value="0" hidden>Select</option>
                      <option
                        v-for="i in userProfile?.owner_details?.max_adult_limit"
                        :key="i"
                      >
                        {{ i }}
                      </option>
                    </select>
                    <div class="error" v-if="owner_errors?.adults">
                      {{ owner_errors?.adults[0] }}
                    </div>
                  </div>
                  <div class="col-6">
                    <label>Children</label>
                    <select v-model="total_child" class="form-control">
                      <option value="0" hidden>Select</option>
                      <option v-for="i in 20" :key="i">{{ i }}</option>
                    </select>
                    <div class="error" v-if="owner_errors?.childrens">
                      {{ owner_errors?.childrens[0] }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-100 villa_card_owner_other_wrapper bg-transparent"
                v-if="
                  userProfile.user_type == 'Owner' &&
                  is_owner_other_booking_show
                "
              >
                <div class="row justify-center">
                  <div class="col-6">
                    <label class="check-box-wrapper" for="freeForFreinds">
                      <input
                        type="checkbox"
                        class="form-control"
                        id="freeForFreinds"
                        v-model="free_for_friend"
                        value="true"
                        @change="makeFree()"
                      />
                      Free For Freinds
                    </label>
                  </div>
                </div>
                <div class="row justify-center">
                  <div class="col-6">
                    <label>Discount Amount</label>
                    <input
                      type="number"
                      v-model="discount_amount"
                      class="form-control"
                      name="discount_amount"
                      placeholder="₹ 0.00"
                      :disabled="free_for_friend"
                    />
                    <div class="error" v-if="owner_errors?.discount_amount">
                      {{ owner_errors?.discount_amount[0] }}
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="w-100 villa_card_owner_other_wrapper"
                v-if="
                  userProfile.user_type == 'Owner' &&
                  is_owner_other_booking_show
                "
              >
                <div class="row align-center">
                  <div class="col-6 justify-start">
                    <p class="price">
                      <span class="rupee">₹</span
                      >{{
                        parseFloat(
                          ownerCalc.final_total
                            ? ownerCalc.final_total -
                                (discount_amount ? discount_amount : 0)
                            : 0
                        )
                          .toString()
                          .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                      }}.00
                    </p>
                  </div>
                  <div class="col-6 justify-end">
                    <button
                      class="main_button_user mt-0"
                      @click="ownerBook"
                      :disabled="is_submit"
                    >
                      <span>Book Now</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </li>
          <!-- end book villa three section -->
        </ul>
      </div>

      <div
        class="main_reserves_row"
        :class="villaList.length == 0 ? `` : `main_reserves_row_new`"
        v-if="
          bookingResult.person &&
          form.rangeDate &&
          userProfile.user_type == 'Guest'
        "
      >
        <div class="check_inr_right">
          <ul class="check_rt_ul">
            <li class="check_rt_li">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Person</span>
                </div>
                <div class="check_numver">
                  <span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M2.33301 11.0833C2.33301 9.47246 3.63884 8.16663 5.24967 8.16663H8.74967C10.3605 8.16663 11.6663 9.47246 11.6663 11.0833C11.6663 12.0498 10.8828 12.8333 9.91634 12.8333H4.08301C3.11651 12.8333 2.33301 12.0498 2.33301 11.0833ZM5.24967 9.33329C4.28318 9.33329 3.49967 10.1168 3.49967 11.0833C3.49967 11.4055 3.76084 11.6666 4.08301 11.6666H9.91634C10.2385 11.6666 10.4997 11.4055 10.4997 11.0833C10.4997 10.1168 9.71617 9.33329 8.74967 9.33329H5.24967Z"
                        fill="#153936"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.99967 2.33329C6.03318 2.33329 5.24967 3.11679 5.24967 4.08329C5.24967 5.04979 6.03318 5.83329 6.99967 5.83329C7.96617 5.83329 8.74967 5.04979 8.74967 4.08329C8.74967 3.11679 7.96617 2.33329 6.99967 2.33329ZM4.08301 4.08329C4.08301 2.47246 5.38884 1.16663 6.99967 1.16663C8.61051 1.16663 9.91634 2.47246 9.91634 4.08329C9.91634 5.69412 8.61051 6.99996 6.99967 6.99996C5.38884 6.99996 4.08301 5.69412 4.08301 4.08329Z"
                        fill="#153936"
                      />
                    </svg>
                    x {{ bookingResult ? bookingResult.person : 0 }}
                  </span>
                </div>
              </div>
            </li>
            <li class="check_rt_li">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Days</span>
                </div>
                <div class="check_numver">
                  <span>{{ form.days }}</span>
                </div>
              </div>
            </li>
            <li class="check_rt_li">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Villa Price</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(showCalc.total ? showCalc.total : 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li class="check_rt_li" v-if="showCalc.extra_person_charge">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Extra Person</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(
                        showCalc.extra_person_charge
                          ? showCalc.extra_person_charge
                          : 0
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li class="check_rt_li" v-if="showCalc.festival_charge">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Festival Charge</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(
                        showCalc.festival_charge ? showCalc.festival_charge : 0
                      )
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <li class="check_rt_li">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>Sub Total</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(showCalc.sub_total ? showCalc.sub_total : 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li>
            <!-- <li class="check_rt_li">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>GST ({{ showCalc.gst_tax_percent }}%)</span>
                </div>
                <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(showCalc.gst_tax ? showCalc.gst_tax : 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div>
              </div>
            </li> -->
            <li class="check_rt_li">
              <div class="check_rt_div">
                <div class="check_text">
                  <span>(18%) GST INC.</span>
                </div>
                <!-- <div class="check_numver">
                  <span
                    ><span class="rupee">₹</span
                    >{{
                      parseFloat(showCalc.gst_tax ? showCalc.gst_tax : 0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                    }}.00</span
                  >
                </div> -->
              </div>
            </li>
          </ul>
          <div class="check_rt_div total_price_col main_button_user_custom">
            <div class="check_rt_div">
              <div class="check_text">
                <span>Grand Total</span>
              </div>
              <div class="check_numver">
                <p class="regu_price">
                  <span class="rupee">₹</span
                  >{{
                    parseFloat(showCalc.final_total ? showCalc.final_total : 0)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                  }}.00
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="main_reserves_col"
          v-if="bookingResult.total && bookingResult.person"
        >
          <button class="main_button_user" type="submit" @click="book">
            <span>Reserve</span>
          </button>
        </div>
      </div>
    </div>
  </MasterLayout>
</template>

<script>
import MasterLayout from "./MasterLayout.vue";
import frame from "../../assets/images/User/Frame.png";
import bk_title_main from "../../assets/images/User/bk_title_main.png";
import first_img from "../../assets/images/User/first_img.png";
import second_img from "../../assets/images/User/second_img.png";
import three_img from "../../assets/images/User/three_img.png";
import four_img from "../../assets/images/User/four_img.png";
import five_img from "../../assets/images/User/five_img.png";
import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import moment from "moment";
import axios from "axios";
import { ref } from "vue";
import Form from "vform";
import { isProxy, toRaw } from "vue";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "MyBooking",
  components: { MasterLayout, Datepicker },
  data() {
    return {
      main_name: "MyBooking",
      link: [{ link: "home", name: "Home" }],
      frame: frame,
      bk_title_main: bk_title_main,
      first_img: first_img,
      second_img: second_img,
      three_img: three_img,
      four_img: four_img,
      five_img: five_img,
      token: localStorage.getItem("auth_cus_token"),
      bookingList: [],
      totalFooterShow: false,
      userProfile: null,
      checkInDateError: "none",
      villaList: [],
      form: new Form({
        id: [],
        rangeDate: ref(),
        check_in_date: "",
        check_out_date: "",
        days: 0,
        total_adult: 0,
        total_child: 0,
        qty: 0,
        booking: {
          total: 0,
          gst_tax: 0,
          extra_person_charge: 0,
          festival_charge: 0,
          gst_tax_percent: 0,
        },
        person: 0,
        villa_booking: [],
      }),
      bookingResult: {
        total: 0,
        extra_person_charge: 0,
        festival_charge: 0,
        gst_tax: 0,
        gst_tax_percent: 0,
        person: 0,
      },
      villa_booking: [],
      villa_type: {
        platinum: {
          qty: 0,
          person: 0,
        },
        diamond: {
          qty: 0,
          person: 0,
        },
        cottages: {
          qty: 0,
          person: 0,
        },
      },
      reserve_error: {
        platinum: {
          error: null,
        },
        diamond: {
          error: null,
        },
        cottages: {
          error: null,
        },
      },
      clickVillaId: [],
      is_owner_villa_avilable: false,
      is_owner_other_booking_show: false,
      discount_amount: null,
      total_child: 0,
      persons: [
        {
          first_name: "",
          middle_name: "",
          last_name: "",
          is_primary: 1,
          user_type: "Guest",
          villa_id: null,
          mobile: "",
        },
      ],
      owner_errors: {},
      is_submit: false,
      free_for_friend: false,
    };
  },
  mounted() {
    const recaptchaScript = document.createElement("script");
    recaptchaScript.setAttribute("src", "./js/script.js");
    document.head.appendChild(recaptchaScript);

    if (!this.token) {
      this.$router.push({ name: "home" });
      window.location.href = "/";
    }
    this.getUserInfo();
  },
  methods: {
    async getUserInfo() {
      this.loader = true;
      await axios
        .get(process.env.VUE_APP_API_CUS_URL + "/user-profile", {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.userProfile = response.data.data;
        })
        .catch((e) => {
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    dateResult() {
      const date = this.form.rangeDate;
      if (date.length > 0 && date.length != undefined) {
        date.map(() => {
          const start_date = moment(date[0]).format("YYYY-MM-DD");
          const end_date = moment(date[1]).format("YYYY-MM-DD");
          this.form.check_in_date = start_date;
          this.form.check_out_date = end_date;

          const dateA = moment(end_date);
          const dateB = moment(start_date);
          this.form.days = dateA.diff(dateB, "days");
        });
      } else {
        if (typeof date != undefined) {
          this.form.check_in_date = moment(date).format("YYYY-MM-DD");
          this.form.check_out_date = moment(date).format("YYYY-MM-DD");
          this.form.days = 1;
        }
      }
    },
    dateChange() {
      this.dateResult();
      if (this.form.rangeDate.length > 0) {
        this.checkInDateError = "none";
      }

      if (this.userProfile.user_type == "Guest") {
        axios
          .post(
            process.env.VUE_APP_API_CUS_URL + "/search-villa",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            this.bookingResult = {
              total: 0,
              extra_person_charge: 0,
              festival_charge: 0,
              gst_tax: 0,
              gst_tax_percent: 0,
              person: 0,
            };
            this.villaList = [];
            this.villaList = response.data.data;
            if (this.villaList) {
              this.villa_booking = [];
              for (var i = 0; i <= this.villaList.length; i++) {
                if (this.villaList[i] && this.villaList[i].villa_name) {
                  var vType = this.villaList[i].villa_name.split(" ");
                  if (vType) {
                    this.villaList[i].pack_type = vType[0].toLowerCase();
                    this.villa_type[vType[0].toLowerCase()] = {
                      qty: 0,
                      person: 0,
                    };
                  }

                  this.villa_booking.push({
                    villa_type_id: this.villaList[i].villaTypeId,
                    quantity: this.villa_type[vType[0].toLowerCase()].qty,
                    person: this.villa_type[vType[0].toLowerCase()].person,
                    villa_name: this.villaList[i].villa_name,
                    check_in_date: this.form.check_in_date,
                    check_out_date: this.form.check_out_date,
                  });
                }
              }
            }
          })
          .catch((e) => {
            console.log("e: ", e);
            if (e.response.status == 401) {
              localStorage.removeItem("auth_otp");
              localStorage.removeItem("auth_user_type");
              localStorage.removeItem("auth_cus_token");

              this.removeLocalstorage();

              this.$router.push({ name: "home" });
              window.location.href = "/";
            }
          });
      } else if (this.userProfile.user_type == "Owner") {
        this.is_owner_villa_avilable = false;
        this.is_owner_other_booking_show = false;
        this.villa_booking = [];
        axios
          .post(
            process.env.VUE_APP_API_URL + "/owner/book-myself",
            {
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
              villa_type_id: this.userProfile.owner_details.villa_type_id,
              villa_id: this.userProfile.owner_details.villa_id,
              check_type: "Owner",
              temp: true,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${this.token}`,
              },
            }
          )
          .then((response) => {
            if (response.data.code == 200) {
              this.is_owner_villa_avilable = true;
            }

            this.villa_booking.push({
              villa_type_id: this.userProfile.owner_details.villa_type_id,
              quantity: 1,
              person: 1,
              villa_name: this.userProfile.owner_details.villa_name,
              check_in_date: this.form.check_in_date,
              check_out_date: this.form.check_out_date,
            });

            this.persons[0].villa_id =
              this.userProfile?.owner_details?.villa_id;

            this.bookingResult = {
              total: 0,
              extra_person_charge: 0,
              festival_charge: 0,
              gst_tax: 0,
              gst_tax_percent: 0,
              person: 0,
            };
          })
          .catch((e) => {
            console.log("e: ", e);
            if (e.response.status == 401) {
              localStorage.removeItem("auth_otp");
              localStorage.removeItem("auth_user_type");
              localStorage.removeItem("auth_cus_token");

              this.removeLocalstorage();

              this.$router.push({ name: "home" });
              window.location.href = "/";
            }
          });
      }
    },
    ownerSelfBooking() {
      axios
        .post(
          process.env.VUE_APP_API_URL + "/owner/book-myself",
          {
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
            villa_type_id: this.userProfile.owner_details.villa_type_id,
            villa_id: this.userProfile.owner_details.villa_id,
            check_type: "Owner",
            temp: false,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          if (response.data.code == 200) {
            this.$router.push({ name: "dashboard" });
          }
        })
        .catch((e) => {
          console.log("e: ", e);
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    selectAdults($events) {
      this.villa_booking[0].person = $events.target.value;
      this.villaBookingTotal();
    },
    ownerOtherBookingShow() {
      this.is_owner_other_booking_show = true;
      this.is_owner_villa_avilable = false;
      this.villaBookingTotal();
    },
    makeFree() {
      this.discount_amount = null;
      if (this.free_for_friend === true) {
        this.discount_amount = this.ownerCalc.final_total;
      }
    },
    ownerBook() {
      this.is_submit = true;
      this.owner_errors = {};

      if (!this.persons[0].first_name) {
        this.owner_errors["first_name"] = ["Please enter first name."];
        return false;
      } else if (!this.persons[0].last_name) {
        this.owner_errors["last_name"] = ["Please enter last name."];
        return false;
      } else if (!this.persons[0].mobile) {
        this.owner_errors["phone_number"] = ["Please enter phone number."];
        return false;
      } else if (!this.villa_booking[0].person) {
        this.owner_errors["adults"] = ["Please select adults."];
        return false;
      } else if (this.ownerCalc.total < this.discount_amount) {
        this.owner_errors["discount_amount"] = ["Please enter valid amount."];
        return false;
      }

      const payload = {
        check_in_date: this.form.check_in_date,
        check_out_date: this.form.check_out_date,
        total_child: this.total_child,
        coupon_code: "",
        coupon_amount: this.discount_amount,
        payment_mode_id: 1,
        total: this.ownerCalc.total,
        extra_person_charge: this.ownerCalc.extra_person_charge,
        festival_charge: this.ownerCalc.festival_charge,
        gst_tax: this.ownerCalc.gst_tax_percent,
        gst_amount: this.ownerCalc.gst_tax,
        person: parseInt(this.villa_booking[0].person),
        sub_total: this.ownerCalc.sub_total - this.discount_amount,
        final_total: this.ownerCalc.final_total - this.discount_amount,
        owner_reference: 1,
        owner_by_discount: this.discount_amount ? 1 : 0,
        owner_id: this.userProfile.id,
        villa_booking: this.villa_booking,
        persons: this.persons,
        free_for_friend: this.free_for_friend,
      };

      axios
        .post(process.env.VUE_APP_API_URL + "/owner/other-booking", payload, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${this.token}`,
          },
        })
        .then((response) => {
          this.is_submit = false;
          if (response.data.code == 200) {
            this.$router.push({ name: "thanks" });
          }
        })
        .catch((e) => {
          console.log("e: ", e);
          this.is_submit = false;
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    removeLocalstorage() {
      localStorage.removeItem("id");
      localStorage.removeItem("check_in_date");
      localStorage.removeItem("check_out_date");
      localStorage.removeItem("total_child");
      localStorage.removeItem("total_adult");
      localStorage.removeItem("qty");

      localStorage.removeItem("total");
      localStorage.removeItem("festival_charge");
      localStorage.removeItem("extra_person_charge");
      localStorage.removeItem("gst_tax");
      localStorage.removeItem("gst_tax_percent");
    },
    villaClick(villa_id) {
      var chek = this.clickVillaId.find((c) => c == villa_id);
      if (chek) {
        console.log(chek);
      } else {
        this.clickVillaId.push(villa_id);
      }
    },
    onChangeVillaQty(villa_id, qty, person) {
      for (let [inQ, villaQ] of this.villa_booking.entries()) {
        if (this.villa_booking[inQ].villa_type_id == villa_id) {
          if (this.form.id.includes(villaQ.villa_type_id)) {
            console.log("villaQ: ", villaQ.villa_type_id);
          } else {
            this.form.id.push(villaQ.villa_type_id);
          }
          this.villa_booking[inQ] = {
            villa_type_id: villaQ.villa_type_id,
            quantity: parseInt(qty.split("_") ? qty.split("_")[1] : 0),
            person: person
              ? parseInt(person.split("_") ? person.split("_")[1] : 0)
              : 0,
            villa_name: villaQ.villa_name,
            check_in_date: this.form.check_in_date,
            check_out_date: this.form.check_out_date,
          };
          this.villaBookingTotal();
        }
      }
    },
    async villaBookingTotal() {
      axios
        .post(
          process.env.VUE_APP_API_CUS_URL + "/villa-booking-total",
          {
            villa_booking: this.villa_booking,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              Authorization: `Bearer ${this.token}`,
            },
          }
        )
        .then((response) => {
          this.bookingResult = response.data.data;
          this.villa_price = this.bookingResult.total;
          if (this.bookingResult) {
            this.bookingResult.gst_tax_percent = this.bookingResult.gst_tax
              ? this.bookingResult.gst_tax
              : 0;

            this.bookingResult.total =
              parseInt(this.bookingResult.total) +
              parseInt(this.bookingResult.extra_person_charge) +
              parseInt(this.bookingResult.festival_charge);

            this.bookingResult.gst_tax =
              (this.bookingResult.total * this.bookingResult.gst_tax_percent) /
              100;

            this.bookingResult.total =
              this.bookingResult.total + this.bookingResult.gst_tax;
          }
          console.log("response: ", this.bookingResult);
        })
        .catch((e) => {
          console.log("e: ", e);
          if (e.response.status == 401) {
            localStorage.removeItem("auth_otp");
            localStorage.removeItem("auth_user_type");
            localStorage.removeItem("auth_cus_token");

            this.removeLocalstorage();

            this.$router.push({ name: "home" });
            window.location.href = "/";
          }
        });
    },
    ...mapActions(["addPlatinum"]),
    book() {
      var reserve_error_count = 0;

      this.villa_booking.forEach((element) => {
        var vType = element.villa_name.split(" ");
        if (element.quantity > 0 && element.person == 0) {
          this.reserve_error[vType[0].toLowerCase()] = {
            error: "Villa quanitity and person are must be selected first!*",
          };
          reserve_error_count++;
        } else {
          this.reserve_error[vType[0].toLowerCase()] = {
            error: "",
          };
        }
      });

      if (reserve_error_count == 0) {
        this.form.booking.total = this.bookingResult.total;
        this.form.booking.gst_tax = this.bookingResult.gst_tax;
        this.form.booking.extra_person_charge =
          this.bookingResult.extra_person_charge;
        this.form.booking.festival_charge = this.bookingResult.festival_charge;
        this.form.booking.gst_tax_percent = this.bookingResult.gst_tax_percent;
        this.form.total_adult = this.bookingResult.person;
        this.form.person = this.bookingResult.person;
        this.form.villa_booking = this.villa_booking;
        this.addPlatinum(this.form);
        let rawData = this.allPlatinums;
        console.log("rawData: ", rawData);
        if (isProxy(this.allPlatinums)) {
          rawData = toRaw(this.allPlatinums);
        }
        if (rawData && rawData[0].id) {
          this.$router.push({ name: "dashboard" });
        }
      }
    },
  },
  computed: {
    ...mapGetters(["allPlatinums"]),
    showCalc: function () {
      const villa_price = parseFloat(
        this.bookingResult.total -
          this.bookingResult.festival_charge -
          this.bookingResult.extra_person_charge -
          this.bookingResult.gst_tax
      );

      const festival_charge = this.bookingResult.festival_charge;
      const extra_person_charge = this.bookingResult.extra_person_charge;

      return {
        total: villa_price,
        festival_charge: festival_charge,
        extra_person_charge: extra_person_charge,
        sub_total: villa_price + festival_charge + extra_person_charge,
        gst_tax: this.bookingResult.gst_tax,
        gst_tax_percent: this.bookingResult.gst_tax_percent,
        final_total: this.bookingResult.total,
      };
    },
    ownerCalc: function () {
      const villa_price = parseFloat(
        this.bookingResult.total -
          this.bookingResult.festival_charge -
          this.bookingResult.extra_person_charge -
          this.bookingResult.gst_tax
      );

      const festival_charge = this.bookingResult.festival_charge;
      const extra_person_charge = this.bookingResult.extra_person_charge;

      return {
        total: villa_price,
        festival_charge: festival_charge,
        extra_person_charge: extra_person_charge,
        sub_total: villa_price + festival_charge + extra_person_charge,
        gst_tax: this.bookingResult.gst_tax,
        gst_tax_percent: this.bookingResult.gst_tax_percent,
        final_total: this.bookingResult.total,
      };
    },
  },
};
</script>

<style scoped>
#dm .dp__input_icons {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  stroke-width: 0;
  font-size: 1rem;
  line-height: 1.5rem;
  padding: 6px 12px;
  color: var(--dp-icon-color);
  box-sizing: content-box;
}
#dm .dp__main {
  width: 100%;
  height: 100%;
  background: #e1ebd3 !important;
  padding: 10px !important;
}

#dm .dp__main div[aria-label="Datepicker input"] {
  width: 100%;
}

#dm .vialla_qty_main select {
  width: 100%;
}

#dm .main_class_inner_sd.select_image {
  width: 100%;
}

#dm .vialla_qty_main:nth-child(1) {
  margin-right: 5px;
}

#dm .vialla_qty_main:nth-child(2) {
  margin-left: 5px;
}

#dm .vialla_qty_main_inner {
  padding: 15px 15px;
}

#dm .vila_title {
  text-transform: capitalize;
  margin-bottom: 10px;
}
#dm .villa_card_wrapper {
  width: 100%;
  display: flex;
}

#dm .villa_card_owner_wrapper {
  margin-top: 20px;
  width: 100%;
}

#dm .villa_card_owner_other_wrapper {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 0 14px #dceae8;
  background: #fff;
  padding: 30px;
  margin-top: 20px;
}

#dm .villa_card_owner_wrapper .main_button_user {
  margin-left: 10px;
  margin-right: 10px;
  padding: 15px 25px;
}

#dm .w-100 {
  width: 100%;
}
.noslotavailable {
  padding: 15px;
  display: flex;
  justify-content: center;
  width: 100%;
  opacity: 0.5;
}
p.reverse_error {
  color: red;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  padding: 10px;
}
.tab_inner_content_main {
  height: fit-content;
  margin: 10px;
  padding: 15px;
  display: inline-flex;
  width: 48%;
}

.tab_inner_content_image {
  width: 30%;
}

.tab_inner_content_image img {
  width: 100%;
  border-radius: 10px;
}

.d_flex {
  display: flex;
}

.tab_inner_content_date {
  padding: 0 10px;
  font-size: 15px;
  width: 100%;
  text-align: left;
}

.tab_inner_content_add_user {
  display: flex;
  align-items: center;
  border-radius: 100px;
  margin-top: 0;
  padding: 0 10px 0 0px;
  background: transparent;
  font-size: 13px;
}
.aview_bar {
  padding: 8px;
}

.lightbox {
  top: 50% !important;
}
.font_bold {
  font-weight: 600;
}

.chkbox {
  display: flex;
  align-items: flex-start;
  height: 20px;
  margin-right: 10px;
}

.activeClick {
  background-color: #fff;
  color: #000;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.cursor_pointer {
  cursor: pointer;
}
.main_reserves_row_new {
  position: relative;
  bottom: auto;
  left: auto;
  right: auto;
}
#dm .d_flex {
  display: flex;
}
#dm .villa_display {
  width: 33%;
  margin-left: 20px;
}

#dm .vila_title_info.activeClick {
  color: #42aea3;
}

/* sidebar */
#dm .main_reserves_row.main_reserves_row_new {
  position: relative !important;
  flex-wrap: wrap;
  border-radius: 10px;
  box-shadow: 0px 0px 14px #dceae8;
  justify-content: end;
}

#dm .check_inr_right {
  width: 50%;
  padding-top: 5px;
}

#dm li.check_rt_li .check_rt_div {
  padding: 10px 0;
}

#dm .total_price_col {
  margin-top: 0px;
  padding: 10px 0px;
}

#dm .tab_content_section .sidebar {
  height: auto;
}

#dm .main_reserves_col_inner {
  width: 100%;
  d: f;
  display: flex;
}

#dm .villa_card_owner_other_wrapper .row {
  display: flex;
}

#dm .villa_card_owner_other_wrapper .row .col-6 {
  display: block;
  width: 50%;
  padding: 15px 10px;
}

#dm .villa_card_owner_other_wrapper .row .col-6 label {
  width: 100%;
  text-align: left;
  margin-bottom: 5px;
}

#dm .villa_card_owner_other_wrapper .row .col-6 input {
  border: 0;
  outline: 0;
  background-color: transparent;
  font-size: 16px !important;
  width: inherit;
  border-bottom: 1px solid #a2dfc7;
  box-shadow: none;
  padding: 0;
  border-radius: 0;
  width: 100%;
}

#dm .villa_card_owner_other_wrapper .row .error {
  text-align: left;
  margin-top: 10px;
  color: red;
  font-size: 10.5px;
}

.justify-center {
  justify-content: center;
}

.justify-start {
  justify-content: start;
  display: flex !important;
}
.justify-end {
  justify-content: end;
  display: flex !important;
}

.bg-transparent {
  background: transparent !important;
  box-shadow: none !important;
}

.align-center {
  align-items: center;
}

.mt-0 {
  margin-top: 0 !important;
}

#dm .main_reserves_col_inner_inner_box {
  margin: auto;
  margin-right: 0px;
}

#dm .main_reserves_col {
  display: flex;
  width: 100%;
}
#dm .bk_title_info_info_main_row {
  margin: 0px 0px;
}
#dm button.main_button_user {
  margin: auto;
  margin-right: 0px;
  margin-top: 17px;
}
#dm .main_reserves_col_inner_inner_box {
  text-align: right;
}
#dm span.margin_top_10 {
  margin-top: 10px;
  display: block;
}

#dm .main_button_user_custom {
  background: #42aea3;
  border: none;
  color: #fff;
  border-radius: 0 0 9px 9px;
  padding: 10px 20px;
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -21px;
}

#dm .dp__main div[aria-label="Datepicker input"] {
  width: 100%;
}

#dm .check-box-wrapper {
  display: flex;
  align-items: center;
  color: #42aea3;
  cursor: pointer;
}

#dm .check-box-wrapper input {
  width: auto !important;
  margin: 0;
  margin-right: 10px;
}

#dm .vialla_qty_main_inner.text-danger {
  padding-top: 23px;
  padding-bottom: 23px;
}

@media all and (min-width: 200px) and (max-width: 991px) {
  #dm .dp__main div[aria-label="Datepicker input"] {
    width: 100%;
  }
  #dm .w3-container {
    padding: 25px 20px;
  }

  #dm .villa_card_wrapper {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }

  #dm .bk_title_info_info_main_row .bk_title_info_info {
    height: auto !important;
  }

  #dm .bk_title_info_info_main_row {
    margin: unset;
  }

  #dm .check_inr_right {
    width: 100%;
  }

  #dm .main_reserves_col {
    justify-content: center;
  }

  #dm button.main_button_user {
    margin: unset;
    margin-right: 0px;
    margin-top: 17px;
  }
}
/* end */
</style>
